<template>
  <div>
    <p v-if="!isLittleScroll" class="label-12 mb-1">Nama Outlet</p>
    <b-dropdown
      aria-role="menu"
      expanded
      append-to-body
      :close-on-click="false"
    >
      <template #trigger>
        <div
          class="input-search-2 flex-center adjust-input-clients border-radius-5"
        >
          <div class="vendor-filter-icon">
            <VendorFilter />
          </div>
          <b-input
            :value="selectedOutlets"
            placeholder="Semua Outlet"
            class="input-none c-w-100"
            readonly
          >
          </b-input>
        </div>
      </template>

      <div class="item-dropdown-client-wrapper">
        <ItemListDropdown
          :datas="stores"
          title="Pilih Outlet"
          idItem="store_id"
          nameItem="store_name"
          class="mb-3"
          @handleSelectData="handleSelectData"
        />
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DropdownClients',
  props: {
    isLittleScroll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VendorFilter: () => import('@/components/icons/VendorFilter.vue'),
    ItemListDropdown: () => import('./ItemListDropdown.vue')
  },
  data () {
    return {
      stores: []
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      getListStores: 'report/getListStores'
    }),
    selectedOutlets () {
      if (this.stores.filter((item) => item.selected).length < 1) {
        return 'Semua Outlet'
      }
      return this.stores
        .filter((item) => item && item.selected)
        .map((item) => item && item.store_name)
        .join(', ')
    }
  },
  methods: {
    handleSelectData (props) {
      this.stores = this.stores.map((item) =>
        item.store_id === props.store_id
          ? { ...item, selected: props.selected }
          : item
      )

      this.$emit('selected', {
        data: props.datas
          .filter((item) => item.selected)
          .map((item) => item.store_id)
          .join(','),
        type: 'stores'
      })
    }
  },
  mounted () {
    this.stores = this.getListStores
  },
  watch: {
    getListStores: {
      handler (val) {
        this.stores = val
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.adjust-input-clients {
  padding: 2.4px;
  padding-left: 20px;
  position: relative;
  width: 230px;
}

.vendor-filter-icon {
  position: absolute;
  left: 5px;
  top: 9.5px;
  z-index: 2;
}

.item-dropdown-client-wrapper {
  overflow: auto;
  max-height: 280px;
}
</style>
